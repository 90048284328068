* {
  box-sizing: border-box;

  /* -ms-overflow-style: none;
  -webkit-scrollbar-display: none; */
  /* scrollbar-width: none;  */
  /* 파이어폭스 */
}
/* 
::-webkit-scrollbar {
  display: none;
} */

body {
  margin: 0;
  font-size: 16px;
  /* [INIT] 메인 Font 변경 */
  font-family: "Pretendard";
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  
  /* [INIT] 메인 text color 변경 */
  color: #000000;
  height: 100%;
  
  /* [INIT] 메인 Background 변경 */
  background-color: #FFF;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
ol,
ul,
li {
  list-style: none;
  padding: 0;
}
input,
button {
  outline: none;
  background-color: transparent;
  /* [INIT] 메인 Font 변경 */
  font-family: "Pretendard";  
  color: #000000;
}

textarea {
  outline: none;
  /* [INIT] 메인 Font 변경 */
  font-family: "Pretendard";
}

.keep-all {
  word-break: keep-all;
}


img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.let-1 {
  letter-spacing: -1px;
}

.w-full {
  width: 100%;
}

.label {
  background-color: #B33936;
}