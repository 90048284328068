@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Black.otf') format('opentype');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('./assets/font/NotoSansKR-Light.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
}



@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('./assets/font/Pretendard-Medium.woff2') format('woff2'), url('./assets/font/Pretendard-Medium.woff') format('woff');
}


@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('./assets/font/Pretendard-Regular.woff2') format('woff2'), url('./assets/font/Pretendard-Regular.woff') format('woff');
}


@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('./assets/font/Pretendard-Bold.woff2') format('woff2'), url('./assets/font/Pretendard-Bold.woff') format('woff');
}



@font-face {
	font-family: 'MaruBuri';
	font-weight: 300;
	font-display: swap;
	src: local('MaruBuri Light'), url('./assets/font/MaruBuri-Light.otf') format('opentype'), url('./assets/font/MaruBuri-Light.ttf') format('truetype');
}
/* 
@font-face {
	font-family: 'MaruBuri';
	font-weight: 500;
	font-display: swap;
	src: local('MaruBuri Medium'), url('./assets/font/MaruBuri-Medium.otf') format('opentype'), url('./assets/font/MaruBuri-Medium.ttf') format('truetype');
} */

@font-face {
	font-family: 'MaruBuri';
	font-weight: 600;
	font-display: swap;
	src: local('MaruBuri SemiBold'), url('./assets/font/MaruBuri-SemiBold.otf') format('opentype'), url('./assets/font/MaruBuri-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'MaruBuri';
	font-weight: 700;
	font-display: swap;
	src: local('MaruBuri Bold'), url('./assets/font/MaruBuri-Bold.otf') format('opentype'), url('./assets/font/MaruBuri-Bold.ttf') format('truetype');
}


.MaruBuri {
  font-family: 'MaruBuri' !important;
}

.NotoSansKR {
	font-family: 'NotoSansKR' !important;
}